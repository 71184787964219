<template>
  <div class="card">
    <div class="card__prewiev">
      <img class="card__prewiev-img" :src="prewiev" alt="">
    </div>
    <h2 class="card__title">{{titleCard}}</h2>
    <div class="card__text">
      <h2 class="card__text-title">{{name}}</h2>
      <p class="card__text-description">{{description}}</p>
    </div>
    <Btn @click="goPage" class="card__button" :name='enabled ? "Открыть" : "Подробнее"'/>
  </div>
</template>

<script>

import Btn from '@/components/ui/Button.vue'

export default {
  name: '',
  components: {
    Btn
  },
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    prewiev: {
      type: String,
      default: ''
    },
    titleCard: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    linkProject: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const goPage = () => {
      setTimeout(() => {
        window.location.href = props.linkProject
      }, 100)
    }

    return {
      goPage
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  height: 555px;
  padding: 35px 38px;

  &__prewiev {
    height: 180px;
    margin-bottom: 20px;
    text-align: center;

    &-img {
      height: 100%;
      width: 240px;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-size: 40px;
    text-align: center;
    color: var(--launcher-text-color);
    transition: color 0.5s;
    transition: color 0.5s;
  }

  &__text {
    margin-bottom: 30px;
    color: var(--launcher-text-color);
    transition: color 0.5s;

    &-title {
      margin-bottom: 22px;
      font-size: 20px;
    }

    &-description {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &__button {
    position: absolute;
    bottom: 35px;
    width: 255px;
  }

  @media(any-hover: hover) {
    &__button:hover {
      opacity: 0.7;
    }
  }
}
</style>
