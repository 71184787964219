<template>
  <div class="launcher">
    <Header :launcher='true'/>
    <h1 class="launcher__title">Все программы</h1>
    <div class="launcher-cards">
      <CardLauncher
        :class="{
          'launcher-cards__item': true,
          'launcher-cards__item_disable': !item.Enabled
        }"
        v-for="(item, i) in projects"
        :key="i"
        :enabled="item.Enabled"
        :prewiev='urlImg + item.Preview'
        :titleCard="item.Title"
        :name="item.Name"
        :description="item.Description"
        :linkProject="item.Link"
      />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'

import Header from '@/components/blocks/Header.vue'
import CardLauncher from '@/components/elements/CardLauncher.vue'

export default {
  name: '',
  components: {
    Header,
    CardLauncher
  },
  setup () {
    const store = useStore()
    const urlImg = window.URL_API

    onBeforeMount(() => {
      store.dispatch('AuthVerification')
      store.dispatch('ListProjects')
    })

    const projects = computed(() => {
      return store.getters.getListProjects?.Projects
    })

    return {
      projects,
      urlImg
    }
  }
}
</script>

<style lang="scss" scoped>
.launcher {
  height: 100vh;
  background-color: var(--launcher-background);
  transition: background-color 0.5s;

  &__title {
    margin: -45px 0px 60px 0px;
    margin-top: -45px;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    color: var(--title);
    transition: color 0.5s;
  }

  &-cards {
    display: flex;
    gap: 65px;
    width: 1140px;
    margin: 0 auto;

    &__item {
      width: 335px;
      border: 1px solid var(--primary-border-color);
      background-color: var(--launcher-card-background);
      border-radius: 15px;
      transition: background-color 0.5s;
    }

    @media(any-hover: hover) {
      &__item:hover {
        background-color: var(--hover-background-card-launcher);
      }
    }

    &__item_disable {
      background-color: var(--hover-background-card-launcher-disable);
    }
  }
}
</style>
